.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px;
}


.listen-button {
  padding: 15px 30px;
  font-size: 1.2rem;
  border: none;
  border-radius: 25px;
  background-color: #61dafb;
  color: #282c34;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 20px 0;
}

.listen-button.listening {
  background-color: #ff4444;
  animation: pulse 1.5s infinite;
}

.transcript, .response {
  width: 80%;
  max-width: 600px;
  margin: 20px 0;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}
